import React, { Suspense, useEffect, useState } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import Navbar from "../Common/Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import Pricing from "components/Pricing";
import Footer from "components/Dashboard/footer";
import GridLine from "components/Common/GridLine";
import { useTheme } from "@emotion/react";

// Lazy-loaded components
const Dashboard = React.lazy(() => import("../Dashboard"));
const About = React.lazy(() => import("../About"));
const Contactus = React.lazy(() => import("../Contactus"));
const Privacy = React.lazy(() => import("../Privacy"));

const Main = () => {

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
      const handleResize = () => {
          setContentHeight(document.body.scrollHeight);
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      // minHeight="80vh"  // Ensures the Box covers the entire height of the viewport
       padding={"0px"}
    >
       <Box>
       <Navbar />
       </Box>

      <Box
        component="main"
        flexGrow="1"  // Makes this section grow and take the remaining space
        padding="0px"
        width="100%"
        marginTop={(smDown)?"6vh":"0vh"}
      >
        {/* Wrap Routes with Suspense */}
        <Suspense fallback={<Box display="flex" justifyContent="center" alignItems="center" ><CircularProgress /></Box>}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/Contactus/*" element={<Contactus />} />
            <Route path="/About/*" element={<About />} />
            <Route path="/Pricing/*" element={<Pricing />} />
            <Route path="/Privacy-polices/*" element={<Privacy />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Box>


    </Box>
  );
};

export default Main;
