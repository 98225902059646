// Footer.js
import zIndex from '@mui/material/styles/zIndex';
import React from 'react';

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        <p style={textStyle}>© 2024 ChartingHQ</p>
        <nav>
          <ul style={navStyle}>
            <li><a href="/about" style={linkStyle}>About</a></li> 
            <li><a style={linkStyle}>|</a></li> 
            <li><a href="/contactus" style={linkStyle}>Contact</a></li>
            <li><a style={linkStyle}>|</a></li> 
            <li><a href="/Privacy-polices/" style={linkStyle}>Privacy Policy</a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '20px 0',
  textAlign: 'center',
  borderRadius: '30px 30px 0 0',  // Rounded top corners
  width: '100%',  // Ensures the footer spans the entire width
  position: 'relative',  // Positioning for flexibility in the layout
  bottom: '0',  // Ensures it stays at the bottom of the container
  boxShadow: '0 -5px 10px rgba(0, 0, 0, 0.2)',  // Adds a subtle shadow for a raised effect
  zIndex:3,
};

const containerStyle = {
  maxWidth: '1000px',
  margin: '0 auto',
};

const textStyle = {
  marginBottom: '10px',
};

const navStyle = {
  listStyleType: 'none',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
};

const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  marginLeft: '15px',
};

export default Footer;
