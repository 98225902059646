import React, { useState, memo } from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconMenu from "./IconMenu";
import { useTheme } from "@emotion/react";


const Navbar = () => {
  const navigate = useNavigate();

  const handleNavigation = (option, path) => {
    localStorage.setItem("page", option);
    navigate(path);
  };

  const matches = useMediaQuery("(max-width:1768px)");
  const md = useMediaQuery("(max-width:900px)");
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  
  return (
    <AppBar
    elevation={0}
    position="fixed"
    sx={{
      backgroundColor: "#000",
      marginBottom: 16,
    }}
  >
      <Grid
        continer
        justifyContent={"space-between"}
        display={"flex"}
        style={{ backgroundColor: "#000" }}
        alignContent={"center"}
        pl={"1rem"}
        pr={"1rem"}

      >
        <Grid item lg={2} md={2} sm={2}>
          <Button component={Link} to="/">
            <img src={logo} alt="ChartingHQ logo" style={{ height: "45px" }} />
          </Button>
        </Grid>
         {(!smDown)?
         <>
        <Grid
          item
          lg={4}
          md={4}
          sm={4}
          justifyContent={"space-between"}
          display={"flex"}
          alignContent={"center"}
          m={"1rem"}
        >
          <NavItem
            label="Home"
            selected={localStorage.getItem("page") === "home"}
            onClick={() => handleNavigation("home", "/")}
          />

          <NavItem
            label="About Us"
            selected={localStorage.getItem("page") === "aboutus"}
            onClick={() => handleNavigation("aboutus", "/About")}
          />

          <NavItem
            label="Contact Us"
            selected={localStorage.getItem("page") === "contactus"}
            onClick={() => handleNavigation("contactus", "/Contactus")}
          />

          <NavItem
            label="Pricing"
            selected={localStorage.getItem("page") === "pricing"}
            onClick={() => handleNavigation("pricing", "/Pricing")}
          />

        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          justifyContent={"space-between"}
          display={"flex"}
          textAlign={"center"}
          alignContent={"center"}
          p={"0.8rem"}
        >
          <Button sx={{ color: "#fff" }}  onClick={() => window.location.href = "https://dashboard.chartinghq.com"}>Sign In</Button>
  
          <Divider
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{
              backgroundColor: "#fff",
              marginRight: "10px",
              marginLeft: "10px",
              width: "1px",

              borderRadius: "20px",
            }}
          />

          <Button sx={{ color: "#fff" }} onClick={() => window.location.href = "https://dashboard.chartinghq.com"}>Sign Up</Button>
        </Grid>
        </>
            :
            <>
           <Grid
          item
          lg={2}
          md={2}
          sm={2}
          justifyContent={"space-between"}
          display={"flex"}
        >
             <IconMenu/>
        </Grid>
         
            </>}


      </Grid>
    </AppBar>
  );
};


const NavItem = ({ label, selected, onClick }) => (
  <Typography
    sx={{
      cursor: "pointer",
      color: "#fff",
      "&:hover": {
        textDecoration: "underline",
      },
      backgroundColor: selected ? "#fff" : "#000",
      padding: "0.3rem 1rem",
      ml:"0.2rem",

      borderRadius: "4px",
      color: selected ? "#000" : "#fff",
    }}
    variant="subtitle2"
    onClick={onClick}
  >
    {label}
  </Typography>
);

const NavItemMobile = ({ label, selected, onClick }) => {
  const md = useMediaQuery("(max-width:900px)");
  const sm = useMediaQuery("(max-width:400px)");

  return (
    <Typography
      sx={{
        cursor: "pointer",
        color: "#fff",
        "&:hover": {
          textDecoration: "underline",
        },
        padding: "4px 8px",
        fontSize: sm ? "11px" : "16px",
        color: selected ? "#8E69FD" : "#fff",
      }}
      variant="subtitle2"
      onClick={onClick}
    >
      {label}
    </Typography>
  );
};

export default memo(Navbar);
