import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import GridLine from "components/Common/GridLine";
import Footer from "components/Dashboard/footer";
import { useTheme } from "@emotion/react";

const Pricing = () => {
    const [contentHeight, setContentHeight] = useState(0);
    
    useEffect(() => {
        localStorage.setItem("page", "pricing");
      }, []);

    useEffect(() => {
        const handleResize = () => {
            setContentHeight(document.body.scrollHeight);
        };

        // Set initial height
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    return (
     
<>

            <Grid container  sx={{ display: 'flex', flexDirection: 'column', height:(smDown)?"100%":"86vh", alignContent:"center", alignItems:"center", justifyContent:"center", }}>
                      <GridLine
                        cellWidth={100}
                        strokeWidth={1}
                        cellWidth2={1}
                        height={contentHeight} // Set height dynamically
                        width={window.innerWidth - 10}
                    />

                <Grid item lg={12} md={12} sm={12} sx={{ zIndex: 1, padding:(smDown)?"2rem":"0rem", marginTop:(!smDown)?"10rem":"2rem", }} >
                    <Typography variant="h4" sx={{ marginBottom: "2rem" }}>Pricing</Typography>
                    <Typography variant="h5" sx={{ marginBottom: "0.5rem" }}>Monthly Plan</Typography>
                    <Typography variant="h5" sx={{ marginBottom: "1rem" }}>₹699 per month</Typography>


                    <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                        <ul>
                            <li><strong>Complete Access:</strong> Utilize all our premium features, including option chains for NIFTY, BANKNIFTY, and FINNIFTY.</li>
                            <li><strong>Advanced Open Interest (OI) Analysis:</strong> Get real-time insights into market activity across multiple strikes.</li>
                            <li><strong>Interactive Strike Selection:</strong> Analyze call and put data seamlessly without switching tabs.</li>
                            <li><strong>Comprehensive Traders Room:</strong> Monitor live charts and essential data, all in one convenient dashboard.</li>
                            <li><strong>Recurring Payments:</strong> Start your subscription today and enjoy hassle-free recurring payments each month.</li>
                        </ul>
                    </Typography>

                    <Typography variant="h5" sx={{ marginBottom: "0.5rem" }}>Why Choose ChartingHQ?</Typography>
                    <Typography variant="body1" sx={{ marginBottom: "3rem" }}>
                        <ul>
                            <li><strong>User-Friendly Interface:</strong> Our platform is designed for both beginners and experienced traders, ensuring a smooth trading experience.</li>
                            <li><strong>Real-Time Data:</strong> Stay informed with up-to-the-minute analytics and insights.</li>
                            <li><strong>All-in-One Solution:</strong> From option chains to OI analysis, ChartingHQ provides everything you need to make informed trading decisions.</li>
                        </ul>
                    </Typography>

                    <Button variant="contained">Start Now</Button>

                </Grid>


          
            </Grid>
            <Footer/>
            </>
      

    );
};

export default Pricing;





