import { Box } from '@mui/material';
import React from 'react';

const GridLines = ({ cellWidth, strokeWidth, height, width }) => {
  // const width1 = 1435; // Set the width of the grid
  // const height1 = 3700; // Set the height of the grid
  const width1 = width; // Set the width of the grid
  const height1 = height; // Set the height of the grid


  // Calculate the number of vertical and horizontal lines
  const verticalLines = Math.ceil(width / cellWidth);
  const horizontalLines = Math.ceil(height / cellWidth);

  return (
    <Box sx={{ position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,}}>
    <svg width={width1} height={height1}>
      {/* Vertical lines */}
      {Array.from({ length: verticalLines }).map((_, index) => (
        <line
          key={index}
          x1={index * cellWidth}
          y1="0"
          x2={index * cellWidth}
          y2={height}
          stroke="#dadada"
          strokeWidth={strokeWidth}
        />
      ))}

      {/* Horizontal lines */}
      {Array.from({ length: horizontalLines }).map((_, index) => (
        <line
          key={index}
          x1="0"
          y1={index * cellWidth}
          x2={width}
          y2={index * cellWidth}
          stroke="#dadada"
          strokeWidth={strokeWidth}
        />
      ))}
    </svg>
    </Box>
  );
};

export default GridLines;
