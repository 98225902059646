import { BrowserRouter, Navigate, Route, Routes,  } from "react-router-dom";
import "./App.css";
import React from "react";
import Main from "./components/Main";
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
// import { ThemeProvider } from "@emotion/react";




function App() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
  
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <Routes>
      <Route
        path="/*"
        element={<Main />}
      />
    </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
