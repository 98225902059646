import { useTheme } from "@emotion/react";
import { Logout } from "@mui/icons-material";
import { Box, Button, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const IconMenu = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState(null);
    const openProfilePopover = Boolean(anchorEl);
    const handleProfileClose = () => {
        setAnchorEl(null);
      };
      const profilePopoverMenu = () => {
        const profile = {
          title: "Mukul",
        };
        const logout = {
          title: "Logout",
          icon: <Logout  />,
          onClick: () => {
           window.location("https://dashboard.chartinghq.com")
          },
        };
        return mobile ? [profile, logout] : [profile, logout];
      };

  
      const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleNavigation = (option, path) => {
        localStorage.setItem("page", option);
        navigate(path);
      };

  return (

  <>
  <Button  onClick={(e) => handleProfileClick(e)}>
  <Grid continer height={"3rem"} justifyContent={"center"} alignContent={"center"} >
           <Grid item sx={{backgroundColor:"#fff", height:"0.2rem", width:"2rem"}}></Grid>
           <Grid item sx={{backgroundColor:"#fff", height:"0.2rem", width:"2rem", marginTop:"0.2rem"}}></Grid>
           <Grid item sx={{backgroundColor:"#fff", height:"0.2rem", width:"2rem", marginTop:"0.2rem"}}></Grid>
      </Grid>
  </Button>


  <Popover
        open={openProfilePopover}
        anchorEl={anchorEl}
        onClose={handleProfileClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ minWidth: 150, padding:"2rem", backgroundColor:"#000", border:"2px solid #fff", borderRadius:"12px"}}> 
        <Grid container sx={{display:"inline", justifyContent:"center", alignContent:"center",}}>
        <Grid item lg={12} md={12} sm={12} justifyContent={"space-around"}>
          <NavItem
            label="Home"
            selected={localStorage.getItem("page") === "db"}
            onClick={() => handleNavigation("db", "/")}
          />

          <NavItem
            label="About Us"
            selected={localStorage.getItem("page") === "oc"}
            onClick={() => handleNavigation("oc", "/About")}
          />

          <NavItem
            label="Contact Us"
            selected={localStorage.getItem("page") === "mo"}
            onClick={() => handleNavigation("mo", "/Contactus")}
          />

          <NavItem
            label="Pricing"
            selected={localStorage.getItem("page") === "tr"}
            onClick={() => handleNavigation("tr", "/Pricing")}
          />


            </Grid>
            <Grid item lg={12} md={12} sm={12} justifyContent={"space-around"} display={"flex"}>
            <Button sx={{color:"#fff", padding:"1rem 5rem 1rem 5rem"}}   onClick={() => window.location.href = "https://dashboard.chartinghq.com"}>SignIn</Button>
            </Grid>
            <Divider style={{height:"0.1rem", width:"16rem", backgroundColor:"#fff"}}/>
            <Grid item lg={12} md={12} sm={12} justifyContent={"space-around"} display={"flex"}>
            <Button sx={{color:"#fff", padding:"1rem 5rem 1rem 5rem"}} onClick={() => window.location.href = "https://dashboard.chartinghq.com"}>SignUp</Button>
            </Grid>
        </Grid>
        </List>
      </Popover>


  </>
  );
};

export default IconMenu;


const NavItem = ({ label, selected, onClick }) => (
  <Typography
    sx={{
      cursor: "pointer",
      color: "#fff",
      "&:hover": {
        textDecoration: "underline",
      },
      backgroundColor: selected ? "#fff" : "#000",
      padding: "0.3rem 1rem",
      ml:"0.2rem",

      borderRadius: "4px",
      color: selected ? "#000" : "#fff",
    }}
    variant="subtitle2"
    onClick={onClick}
  >
    {label}
  </Typography>
);